import { Link, useNavigate } from "react-router-dom";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { Company } from "../domain/company";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import { Currency } from "../../billing/components";
import { useState } from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import { EditCompanyModal } from "../modals/edit-company-modal";
import {
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";

export const BasicInfo = ({
    company,
    applicationPercentageProgress: applicationProgress,
    owner,
    onCompanyUpdated,
    applicationData,
}: {
    company: Company;
    applicationPercentageProgress: number;
    owner: User;
    onCompanyUpdated: (company: Company) => void;
    applicationData?: Record<string, any>;
}) => {
    const [editModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const w2Employees = applicationData ? getW2Employees({ data: applicationData }) : null;
    const estimatedValue = applicationData ? getEstimatedPayoutFromData({ data: applicationData }) : 0;

    return (
        <div>
            {editModalOpen && (
                <EditCompanyModal
                    company={company}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    onCompanyUpdated={(company) => {
                        onCompanyUpdated(company);
                        setModalOpen(false);
                    }}
                />
            )}
            <Table>
                <TBody>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top" }}>Company</Td>
                        <Td>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col">
                                    <div style={{ fontSize: 16 }}>
                                        <b>{company.name}</b>
                                    </div>
                                    <div style={{ color: "gray" }}>{company.address || "No address set"}</div>
                                </div>
                                <ButtonNeoGen
                                    size="sm"
                                    type="outline"
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    Edit
                                </ButtonNeoGen>
                            </div>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td style={{ verticalAlign: "top" }}>Owner</Td>
                        <Td>
                            <div className="flex flex-col">
                                <b style={{ fontSize: 16 }}>{[owner.firstName, owner.lastName].join(" ")}</b>
                                <div>
                                    {owner.email ? (
                                        <a
                                            style={{ color: "gray", textDecoration: "underline" }}
                                            href={`mailto:${owner.email}`}
                                        >
                                            {owner.email}
                                        </a>
                                    ) : (
                                        "No email set"
                                    )}
                                </div>
                                <div>
                                    {owner.phone ? (
                                        <a
                                            style={{ color: "gray", textDecoration: "underline" }}
                                            href={`tel:${owner.phone}`}
                                        >
                                            {formatPhonenumber(owner.phone)}
                                        </a>
                                    ) : (
                                        "No phone number set"
                                    )}
                                </div>
                            </div>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td style={{ verticalAlign: "top" }}>Application</Td>
                        <Td>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col">
                                    <div>
                                        Progress: <b>{applicationProgress}%</b>
                                    </div>
                                    <div>
                                        2020 W2 Employees: <b>{w2Employees?.w2Employees2020 || 0}</b>
                                    </div>
                                    <div>
                                        2021 W2 Employees: <b>{w2Employees?.w2Employees2021 || 0}</b>
                                    </div>
                                    <div>
                                        Estimated value:{" "}
                                        <b>
                                            <Currency amount={estimatedValue} />
                                        </b>
                                    </div>
                                </div>
                                <div>
                                    <ButtonNeoGen
                                        size="sm"
                                        type="outline"
                                        onClick={() => {
                                            navigate(`/companies/${company.id}?tab=application`);
                                        }}
                                    >
                                        View application
                                    </ButtonNeoGen>
                                </div>
                            </div>
                        </Td>
                    </Tr>
                </TBody>
            </Table>
        </div>
    );
};
