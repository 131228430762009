import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { Company } from "../domain/company";
import { SelectField } from "../../layout/form/select-field";
import { useFieldArray } from "react-hook-form";
import { useMemo, useState } from "react";
import { AddMemberModal } from "./add-member-modal";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { EditMemberModal } from "./edit-member-modal";
import { sortUsers } from "../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../hooks/use-setup-company";
import { v4 } from "uuid";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { NumberField } from "../../layout/form/number-field";

const schema = z.object({
    name: z.string().min(1),
    address: z.string().optional(),
    affiliateId: z.string().uuid().nullish(),
    ownerFullName: z.string().min(1),
    ownerEmail: z.string().email().min(1),
    ownerPhone: z.string().min(1),
    w2_2020: z.coerce.number(),
    w2_2021: z.coerce.number(),
});

type Data = z.infer<typeof schema>;

export const NewCompanyModal = ({
    onClose,
    onCompanyCreated,
    affiliates,
    roleGroups,
}: {
    onClose: () => any;
    onCompanyCreated: ({ company, owner }: { company: Company; owner: User }) => any;
    affiliates: User[];
    roleGroups: RoleGroup[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema });

    const setupCompanyMutation = useSetupCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const { company, owner } = await setupCompanyMutation.mutateAsync({
                authToken,
                data: {
                    company: { name: data.name, address: data.address },
                    owner: {
                        firstName: data.ownerFullName.split(" ")[0],
                        lastName: data.ownerFullName.split(" ")[1],
                        email: data.ownerEmail,
                        phone: data.ownerPhone,
                        userStatus: "invited",
                    },
                    w2Employees: {
                        "2020": data.w2_2020,
                        "2021": data.w2_2021,
                    },
                },
            });
            return { company, owner };
        },
    });

    const handleSubmit = async (data: Data) => {
        const { company, owner } = await submitMutation.mutateAsync(data);
        onCompanyCreated({ company, owner });
    };

    const affiliateOptions = useMemo(
        () =>
            (sortUsers(affiliates as any[]) as User[]).map((affiliate) => ({
                value: affiliate.id,
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliates],
    );

    return (
        <>
            <ModalDialog show title={"New Company"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit)} error={setupCompanyMutation.error as any}>
                    <div className="flex flex-row gap-10">
                        <div className="flex-1">
                            <TextField label="Name" {...form.getFieldProps("name")} placeholder="ACME Corp" />
                            <TextField
                                label="Address"
                                {...form.getFieldProps("address")}
                                placeholder="513 Ocean View Way, Miami, Florida, USA"
                            />
                            <div className="flex flex-row gap-5 flex-1">
                                <div className="flex-1">
                                    <NumberField label="Est. W2 Employees 2020" {...form.getFieldProps("w2_2020")} />
                                </div>
                                <div className="flex-1">
                                    <NumberField label="Est. W2 Employees 2021" {...form.getFieldProps("w2_2021")} />
                                </div>
                            </div>
                            <h3>Owner details</h3>
                            <TextField
                                label="Full name"
                                {...form.getFieldProps("ownerFullName")}
                                placeholder="John Doe"
                            />
                            <TextField
                                label="Email"
                                {...form.getFieldProps("ownerEmail")}
                                placeholder="john@example.com"
                            />
                            <TextField
                                label="Phone"
                                {...form.getFieldProps("ownerPhone")}
                                placeholder="+44 123 456 789"
                            />
                            <SelectField
                                label="Affiliate"
                                {...form.getFieldProps("affiliateId")}
                                options={affiliateOptions}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={setupCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={setupCompanyMutation.isLoading}>
                            Create Company
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
