import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { companySchema } from "../domain/company";
import { getCompany } from "./get-company";

export type UpdateCompanyData = {
    name: string;
    ownedById?: string;
};

export const updateCompany = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: UpdateCompanyData;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/companies/${id}`,
        authToken,
        data,
    });

    const updatedCompany = await getCompany({ authToken, id });

    return updatedCompany;
};
