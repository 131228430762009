import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { Form } from "../../layout/form/form";
import { EmailField } from "../../layout/form/email-field";
import { useForm } from "../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUserCompany } from "../../user-companies/actions/create-user-company";
import { getUsers } from "../../jason-proof-of-concept/users/actions/get-users";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../layout/form/select-field";

const initialFormSchema = z.object({
    email: z.string().email(),
    roleId: z.number(),
});

type InitialFormData = z.infer<typeof initialFormSchema>;

export const InviteExistingUserModal = ({
    onClose,
    companyId,
    onUserAdded,
    companyRoles,
}: {
    onClose: () => any;
    companyId: number;
    onUserAdded: (user: User) => any;
    companyRoles: NonNullable<User["companyRoleGroups"]>;
}) => {
    const [user] = useRecoilState(userAtom);
    if (!user.id) {
        throw new Error("User not defined.");
    }
    const initialForm = useForm({ schema: initialFormSchema });
    const authToken = getAuthTokenNoThrow() || "no-token";

    const initialMutation = useMutation({
        mutationFn: async (data: InitialFormData) => {
            const [user] = await getUsers({ authToken, filters: { where: { email: data.email } } });
            if (!user) {
                throw new Error("No existing user found.");
            }
            const [existingUserCompany] = await getUserCompanies({
                authToken,
                filters: { where: { ercUserId: user.id, companyId } },
            });
            if (existingUserCompany) {
                throw new Error("User already in company.");
            }
            const userCompany = await createUserCompany({
                authToken,
                data: { userId: user.id, companyId, roleId: data.roleId },
            });
            return { user, userCompany };
        },
    });

    const handleSubmit = async (data: InitialFormData) => {
        const { user, userCompany } = await initialMutation.mutateAsync(data);
        if (userCompany) {
            onUserAdded(user);
        }
    };

    const options = companyRoles.map((role) => ({ label: role.name || "", value: role.id as number }));

    return (
        <ModalDialog title="Add user to company" close={onClose} show showOk={false} showCancel={false}>
            <p className="px-4 text-gray-400 text-center">Invite an existing user on the system to this company.</p>
            <div className="pt-6">
                <Form onSubmit={initialForm.handleSubmit(handleSubmit)} error={initialMutation.error as any}>
                    <EmailField
                        autoComplete="off"
                        label="Enter user email address"
                        {...initialForm.getFieldProps("email")}
                    />
                    <SelectField label="Select role" {...initialForm.getFieldProps("roleId")} options={options} />
                    <ButtonNeoGen block type="submit" disabled={initialMutation.isLoading}>
                        Invite user
                    </ButtonNeoGen>
                </Form>
            </div>
        </ModalDialog>
    );
};
