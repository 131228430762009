import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { Company } from "../domain/company";
import { SelectField } from "../../layout/form/select-field";
import { useMemo } from "react";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../sections/utilities/sortUsers";
import { useMutation } from "@tanstack/react-query";
import { useSetupCompany } from "../hooks/use-setup-company";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { useUpdateCompany } from "../hooks/use-update-company";

const schema = z.object({
    name: z.string().min(1),
    address: z.string().optional(),
});

type Data = z.infer<typeof schema>;

export const EditCompanyModal = ({
    onClose,
    company,
    onCompanyUpdated: onCompanyUpdated,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: { name: company.name || undefined, address: company.address || undefined },
    });

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    name: data.name,
                    address: data.address,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog show title={"Edit Company"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <TextField label="Name" {...form.getFieldProps("name")} placeholder="ACME Corp" />
                    <TextField
                        label="Address"
                        {...form.getFieldProps("address")}
                        placeholder="513 Ocean View Way, Miami, Florida, USA"
                    />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Company
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
