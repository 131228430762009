import { useState } from "react";
import { AddUserToCompanyModal } from "../modals/add-user-to-company-modal";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { EditUserModal } from "../modals/edit-user-modal";
import { UserCompany } from "../../user-companies/domain/user-company";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import ButtonNeoGen from "../../layout/button-neogen";
import OptionsDropDown from "../../layout/options-dropdown";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import { InviteExistingUserModal } from "../modals/invite-existing-user-modal";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import Avatar from "react-avatar";
import { Company } from "../domain/company";
import { ReInviteUserModal } from "../modals/re-invite-user-modal";
import { timeAgo } from "../../sections/users/cards/calls-list";

export const Members = ({
    company,
    onUserAdded,
    onUserUpdated,
    onRemoveUser,
    onUserInvited,
    companyRoles,
    userCompanies,
    users,
}: {
    company: Company;
    onUserAdded: (user: User) => void;
    onUserUpdated: (user: User) => void;
    onRemoveUser: (userId: string) => void;
    onUserInvited: () => void;
    companyRoles: RoleGroup[];
    userCompanies: UserCompany[];
    users: User[];
}) => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState<User | undefined>();
    const [userToReSend, setUserToResend] = useState<User | undefined>();
    const companyId = company.id;

    return (
        <div>
            {showAddUserModal && (
                <AddUserToCompanyModal
                    onClose={() => setShowAddUserModal(false)}
                    companyId={companyId}
                    onUserAdded={(user) => {
                        setShowAddUserModal(false);
                        onUserAdded(user);
                    }}
                    companyRoles={companyRoles as any[]}
                />
            )}
            {showInviteUserModal && (
                <InviteExistingUserModal
                    onClose={() => setShowInviteUserModal(false)}
                    companyId={companyId}
                    onUserAdded={(user) => {
                        setShowInviteUserModal(false);
                        onUserAdded(user);
                    }}
                    companyRoles={companyRoles as any[]}
                />
            )}
            {userToEdit && (
                <EditUserModal
                    onClose={() => setUserToEdit(undefined)}
                    user={userToEdit}
                    companyId={companyId}
                    onUserUpdated={(user) => {
                        setUserToEdit(undefined);
                        onUserUpdated(user);
                    }}
                    currentRole={
                        companyRoles.filter((r) =>
                            userCompanies
                                .filter((uc) => uc.ercUserId === userToEdit.id && uc.companyId === companyId)
                                .map((uc) => uc.roleGroupId)
                                .includes(r.id || -1),
                        )[0]
                    }
                    companyRoles={companyRoles as any[]}
                />
            )}
            {userToReSend && (
                <ReInviteUserModal
                    user={userToReSend}
                    onClose={() => setUserToResend(undefined)}
                    companyId={company.id}
                    onUserInvited={() => {
                        setUserToResend(undefined);
                        onUserInvited();
                    }}
                />
            )}
            <div className="flex justify-end mb-2">
                <div className="flex gap-2">
                    <ButtonNeoGen type="outline" onClick={() => setShowInviteUserModal(true)}>
                        Invite existing user
                    </ButtonNeoGen>
                    <ButtonNeoGen onClick={() => setShowAddUserModal(true)}>Add new user</ButtonNeoGen>
                </div>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th style={{ width: "50%" }}>Name</Th>
                        <Th>Role</Th>
                        <Th>Status</Th>
                        <Th></Th>
                    </Tr>
                </THead>
                <TBody>
                    {users.map((user) => {
                        const currentRole = companyRoles.filter((r) =>
                            userCompanies
                                .filter((uc) => uc.ercUserId === user.id && uc.companyId === companyId)
                                .map((uc) => uc.roleGroupId)
                                .includes(r.id || -1),
                        )[0] as RoleGroup | undefined;

                        const statusDate =
                            user.userStatus === "invited"
                                ? user.invitedAt
                                : user.userStatus === "active"
                                ? user.accountSetupAt
                                : undefined;

                        return (
                            <Tr key={user.id}>
                                <Td>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row items-center">
                                            <div className="pr-3">
                                                <Avatar
                                                    name={[user.firstName, user.lastName].join(" ")}
                                                    size="44"
                                                    email={user.email}
                                                    round={true}
                                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400  "
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <div>{[user.firstName, user.lastName].join(" ")}</div>
                                                <div>
                                                    <a
                                                        style={{ textDecoration: "underline" }}
                                                        href={`mailto:${user.email}`}
                                                    >
                                                        {user.email}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        style={{ textDecoration: "underline" }}
                                                        href={`tel:${user.phone}`}
                                                    >
                                                        {user.phone && formatPhonenumber(user.phone)}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Td>
                                <Td>{currentRole?.name || "-"}</Td>
                                <Td>
                                    <div className="flex flex-col">
                                        <div>
                                            {user.emailVerified || user.userStatus === "active"
                                                ? "Active"
                                                : user.userStatus === "invited"
                                                ? "Invited"
                                                : "Inactive"}
                                        </div>
                                        {statusDate && (
                                            <div className="text-gray-400">{timeAgo(statusDate).toString()}</div>
                                        )}
                                    </div>
                                </Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="flex flex-1 justify-end">
                                        <div style={{ width: 120 }}>
                                            <OptionsDropDown
                                                options={[
                                                    { label: "Edit user", action: () => setUserToEdit(user) },
                                                    ...(!user.emailVerified
                                                        ? [
                                                              {
                                                                  label: "Resend invitation email",
                                                                  action: () => setUserToResend(user),
                                                              },
                                                          ]
                                                        : []),
                                                    {
                                                        label: "Remove from company",
                                                        action: () => {
                                                            SwalNeogenFire({
                                                                darkMode: getDarkMode(),
                                                                title: "Remove user from company?",
                                                                text: "Are you sure you want to remove this user?",
                                                                icon: "info",
                                                                showCancelButton: true,
                                                                confirmButtonText: "Yes, remove!",
                                                                cancelButtonText: "No, cancel!",
                                                                showLoaderOnConfirm: true,
                                                            }).then(async (result) => {
                                                                if (result.isConfirmed) {
                                                                    onRemoveUser(user.id);
                                                                }
                                                            });
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </Td>
                            </Tr>
                        );
                    })}
                </TBody>
            </Table>
        </div>
    );
};
