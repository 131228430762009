import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export const companySchema = z.object({
    id: z.number(),
    name: z.string().nullish(),
    ownedById: z.string().nullish(),
    ownedBy: userSchema.nullish(),
    address: z.string().nullish(),
});

export type Company = z.infer<typeof companySchema>;
