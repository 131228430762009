import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import { search as ssSearch } from "ss-search";
import { debounce } from "lodash";
import { NewCompanyModal } from "../modals/new-company-modal";
import { useCompanies } from "../hooks/use-companies";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { Company } from "../domain/company";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useCreateCompany } from "../hooks/use-create-company";
import { useNavigate } from "react-router";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import { formatPhonenumber } from "../../sections/utilities/formatters";
import OptionsDropDown from "../../layout/options-dropdown";

function Companies() {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const companiesQuery = useCompanies({ authToken });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);
    const filteredCompanies = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      companies,
                      [
                          "name",
                          "email",
                          "phone",
                          "address",
                          "website",
                          "ownedBy.firstName",
                          "ownedBy.lastName",
                          "ownedBy.email",
                          "ownedBy.phone",
                      ],
                      debouncedSearch,
                  ) as Company[])
                : companies,
        [companies, debouncedSearch],
    );

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    const roleGroupsQuery = useRoleGroups({ authToken });
    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);

    return (
        <>
            {showNewCompanyModal && (
                <NewCompanyModal
                    onClose={() => setShowNewCompanyModal(false)}
                    onCompanyCreated={({ company }) => {
                        companiesQuery.refetch();
                        navigate(`/companies/${company.id}`);
                    }}
                    affiliates={users}
                    roleGroups={roleGroups}
                />
            )}
            <PageDescription
                title="Companies"
                description2="This is a list of all companies. You can add, edit and delete companies."
                buttons={[
                    {
                        label: "New Company",
                        icon: "fas fa-plus",
                        onClick: () => {
                            setShowNewCompanyModal(true);
                        },
                    },
                ]}
            >
                <SearchField search={search} setSearch={setSearch} placeholder="Search..." />
            </PageDescription>
            <div>
                {companiesQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : (
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Owner</Th>
                                <Th>Owner phone</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {filteredCompanies.map((company) => {
                                return (
                                    <Tr
                                        key={company.id}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate(`/companies/${company.id}`);
                                        }}
                                    >
                                        <Td>
                                            <b>{company.name}</b>
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            {company.ownedBy ? (
                                                <div className="flex flex-col">
                                                    <div>
                                                        {[company.ownedBy?.firstName, company.ownedBy?.lastName].join(
                                                            " ",
                                                        )}
                                                    </div>
                                                    <div className="text-sm text-gray-400">
                                                        {company.ownedBy?.email}
                                                    </div>
                                                </div>
                                            ) : (
                                                "-"
                                            )}
                                        </Td>
                                        <Td>
                                            {company.ownedBy?.phone ? formatPhonenumber(company.ownedBy.phone) : "-"}
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                )}
            </div>
        </>
    );
}

export default Companies;
